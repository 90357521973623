import type {
  ContentArticle,
  SubjectInfo,
} from '~/typesManual/content_api/article'
type ArticleType =
  | 'instream'
  | 'news'
  | 'writer'
  | 'person'
  | 'column'
  | 'subject'
  | 'articletype'
  | 'subject'

type Props = {
  alreadyFetchedArticles?: ContentArticle[]
  paper?: number
  type?: ArticleType
  articleTypeId?: number
  count?: number
  subject?: number
  page?: number
  offset?: number
  rss?: boolean
  podcastTypes?: string
  frontend?: boolean
  primaryOnly?: boolean
}
export async function useArticles({
  paper,
  type,
  articleTypeId,
  count,
  podcastTypes,
  subject,
  page,
  alreadyFetchedArticles,
  rss = false,
  offset = 0,
  frontend = false,
  primaryOnly = false,
}: Props) {
  const route = useRoute()
  const nuxtApp = useNuxtApp()
  const indexStore = useIndexStore()

  const currentPage = ref<number | undefined>(page)
  const pending = ref(false)
  const error = ref<unknown>()
  const currentCount = ref(count ?? 0)

  const cacheKey = `articles-${articleTypeId}-${count}-${paper}-${offset}-${frontend}-${subject}-${type}-${primaryOnly}-${podcastTypes}-${
    currentPage.value
  }-${JSON.stringify(route.params)}-${indexStore.currentPaper?.RecordId}`

  const articles = useState<ContentArticle[]>(cacheKey, () => [])

  async function loadMore(newOffset?: number) {
    if (!currentPage.value) {
      currentPage.value = 0
    }
    currentPage.value += 1

    offset = newOffset ?? 0

    await loadArticles(true)
  }

  function isSubjectInfo(
    article: ContentArticle | SubjectInfo
  ): article is SubjectInfo {
    return (article as SubjectInfo)?.name !== undefined
  }

  async function loadArticles(refresh = false) {
    const setPaper =
      paper || (indexStore.currentPaper ? indexStore.currentPaper.RecordId : 1)

    if (alreadyFetchedArticles?.length) {
      articles.value = alreadyFetchedArticles.slice(0, count)
    }
    if (articles.value.length > 0 && !refresh) return
    pending.value = true
    if (rss) {
      articles.value = await nuxtApp.$api.content.rss({
        paper: setPaper,
        type: articleTypeId,
        count: count,
      })
    } else {
      const newArticles =
        isSite('altinget_dk') && frontend && [1].includes(setPaper)
          ? // Fetch frontpage articles from Saga
            await nuxtApp.$api.content.frontpageArticles({
              paper: setPaper,
              count,
              offset,
            })
          : // Fetch other articles from MailContentApi
            await nuxtApp.$api.content.articles({
              paper:
                paper ||
                (indexStore.currentPaper
                  ? indexStore.currentPaper.RecordId
                  : 1),
              id: getId(),
              type: type,
              count: count,
              page: currentPage.value,
              offset: offset,
              primaryOnly: primaryOnly,
              frontend: frontend,
              podcastTypes: podcastTypes ?? route.params?.podcast?.toString(),
            })

      const combinedArticles = [...articles.value, ...newArticles]
      const filteredArticles = combinedArticles.filter((article) => {
        return (
          !isSubjectInfo(article) &&
          (article.HeadLine ||
            article.Headline ||
            article.SponsoredArticle ||
            article.FrontpageTheme ||
            article.FeaturedArticle)
        )
      }) as ContentArticle[]
      currentCount.value += filteredArticles.length
      articles.value = filteredArticles
    }

    pending.value = false
  }

  try {
    await loadArticles()
    error.value = undefined
  } catch (e) {
    console.error(e)

    error.value = e
    pending.value = false
  }

  function getId(): string | number | undefined {
    // If type matches any of the given types, return 1
    if (type && ['column', 'instream', 'news'].includes(type)) {
      return 1
    }

    if (route.params?.type?.toString()) {
      return route.params.type.toString()
    }

    if (route.params?.person?.toString()) {
      return route.params.person.toString()
    }

    if (route.params?.writer?.toString()) {
      return route.params.writer.toString()
    }

    return articleTypeId ?? route.params?.subject?.toString() ?? subject
  }
  return {
    pending,
    loadMore,
    data: articles,
    error,
  }
}
